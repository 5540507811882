import axios from 'axios';
import toast from 'react-hot-toast';
import appConfig from '../Utility/AppConfig';
import { useDeviceData } from './deviceData';

export const useCallAPI = () => {

    const device = useDeviceData();

    const getResults = async (callFn, data = {}) => {
        return await new Promise(async (resolve, reject) => {
            try {
                const headers = {
                    'Content-Type': 'application/json',
                    'deviceid': device.getDeviceId(),
                    'token': device.getToken(),
                }
                const res = await axios.post(appConfig.apiEndpoint + callFn, data, { headers: headers });
                if(res.data.state === true) {
                    resolve(res.data.data);
                } else {
                    throw res;
                }
            } catch (err) {
                if(err.response?.data?.state === false) {
                    toast.error(err.response.data.error.message);
                    reject(err.response.data.error);
                } else {
                    console.log(err);
                    toast.error('Internal Server Error')
                    reject(err);
                }
            }
        });
    }

    return getResults;

};