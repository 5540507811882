import { ThemeProvider } from "@material-tailwind/react";
import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from "react-ga4";
import 'react-spring-bottom-sheet/dist/style.css';
import App from './App';
import appConfig from "./Utility/AppConfig";
import "./index.css";
import reportWebVitals from './reportWebVitals';
import 'react-day-picker/dist/style.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
ReactGA.initialize(appConfig.gaID);
root.render(
  <ThemeProvider>
    <App />
  </ThemeProvider>
);
const SendAnalytics = ()=> {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics);