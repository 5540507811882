import React from "react";
import {
  Navigate,
  Route,
  Routes
} from "react-router-dom";

//Import Auth pages
import Login from "../Pages/auth/Login";

//Import dashboard pages
import Dashboard from "../Pages/dash/Dashboard";
import Manage from "../Pages/dash/Manage";
import PaymentGateway from "../Pages/dash/PaymentGateway";
import PayoutGateway from "../Pages/dash/PayoutGateway";

export default function AppRouter() {
  return (
    <Routes>
        {/* Dashboard Routes */}
        <Route exact path="/" element={<Navigate to="/dashboard" />}></Route>

        <Route path="/dashboard" element={<Dashboard />}></Route>
        <Route path="/manage/:view" element={<Manage />}></Route>

        <Route path="/paymentgateway/:view" element={<PaymentGateway />}></Route>
        <Route path="/payoutgateway/:view" element={<PayoutGateway />}></Route>

        {/* Authentication Routes */}
        <Route path="/login" element={<Login />}></Route>

        {/* Referral Link */}
        <Route path="/ref/:ref" element={<Login />}></Route>

    </Routes>
  );
}