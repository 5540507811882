import { yupResolver } from '@hookform/resolvers/yup';
import { Alert } from '@material-tailwind/react';
import React, { useEffect, useState } from "react";
import { ArrowRight } from "react-feather";
import { useForm } from "react-hook-form";
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { useUserAuth } from '../../../Contexts/authContext';
import { useCallAPI } from "../../../Hooks/callAPI";
import { useDeviceData } from '../../../Hooks/deviceData';
import { useWrite } from '../../../Hooks/writeContract';
import { ButtonPrimaryType2 } from "../../../Utility/Buttons";
import { FormInput } from '../../../Utility/Inputs';
import { ContentBox, LoadingData } from "../../../Utility/Utility";

const ManageMerchant = ({ data }) => {

  const callAPI = useCallAPI();
  const auth = useUserAuth();
  const device = useDeviceData();
  const writeContract = useWrite();

  const [merchantData, setMerchantData] = useState(false);
  const [formError, setFormError] = useState({});
  const [blockDiv, setBlockDiv] = useState(false);

  useEffect(() => {
    if(auth.appBooted) {
      getMerchantData();
    }
  }, [auth]);

  const getMerchantData = async () => {
    try {
        const res = await callAPI("merchants/get");      
        setMerchantData(res);
        reset({
            address: res.address,
            email: res.email,
            name: res.name,
            website: res.website,
            supportUrl: res.supportUrl,
            logo: res.logo
        });
    } catch (error) {
        console.log(error);
    }
  }

  const validationSchema = Yup.object().shape({
      email: Yup.string()
          .email('Please enter valid email')
          .required('Please enter your Email'),
      name: Yup.string()
          .required('Please enter your Merchant Name')
          .min(1, "Please enter atleast 1 characters")
          .max(50, "Please keep entity name under 50 characters"),
      website: Yup.string().url('Invalid URL').required('Website is required'),
      supportUrl: Yup.string().url('Invalid URL').required('Support Url is required'),
      logo: Yup.string().url('Invalid URL').required('Logo is required'),
  });

  const {
      register, reset, formState: { errors, isValid, isDirty }, handleSubmit, getValues, setValue, control, watch
  } = useForm({ mode: 'onChange', resolver: yupResolver(validationSchema) })

  const onSubmit = async () => {
    try {

        setBlockDiv(true);
        setFormError({});
        
        const formData = getValues();
        const message = formData.name + formData.email + formData.logo + formData.website + formData.supportUrl + device.getToken()
        
        const sigHash = await writeContract.SignMessage(message);
        formData.hash = sigHash;
        await callAPI("merchants/set", formData);
        toast.success("Merchant Updated Successfully.");
        auth?.bootApp();
        setBlockDiv(false);

    } catch (error) {
        if(error.code && error.code === 403) { //Handle form error
            setFormError(error.errData);
        }
        setBlockDiv(false);
    }
  }

  return (
    <ContentBox>
        {merchantData ?
            <form onSubmit={handleSubmit(onSubmit)} className='space-y-4 sm:space-y-0'>
                <Alert color='indigo' variant='gradient' className='text-sm mb-5'>We employ advanced wallet signature verification methods to ensure the integrity and security of your account. This stringent authentication process verifies the origin of each request, guaranteeing that only authorized transactions from your designated wallet are processed. This robust security measure provides unparalleled protection against unauthorized access and fraudulent activities, safeguarding the integrity of your account. We request you to keep your wallet safe and handy whenever you want to update the merchant.</Alert>
                <FormInput name='address' label="Wallet Address" disabled helper="Your wallet address associated with this merchant" reg={register} formerror={formError} errors={errors} isdirty={isDirty ? 1 : 0} />
                <FormInput name='email' label="Email Address" placeholder="john@example.com" helper="Used to notify you about important activities" reg={register} formerror={formError} errors={errors} isdirty={isDirty ? 1 : 0} />
                <FormInput name='name' label="Project Name" placeholder="AcmeCompany" helper="Enter your Project Name" reg={register} formerror={formError} errors={errors} isdirty={isDirty ? 1 : 0} />
                <FormInput name='website' label="Website URL" placeholder="https://www.example.com" helper="Enter your Project home URL" reg={register} formerror={formError} errors={errors} isdirty={isDirty ? 1 : 0} />
                <FormInput name='supportUrl' label="Support URL" placeholder="https://www.example.com" helper="Enter your Project Support URL" reg={register} formerror={formError} errors={errors} isdirty={isDirty ? 1 : 0} />
                <FormInput name='logo' label="Logo URL" placeholder="https://www.example.com/logo.png" helper="Preferred .png or .svg format" reg={register} formerror={formError} errors={errors} isdirty={isDirty ? 1 : 0} />
                <ButtonPrimaryType2 type="submit" disabled={!isDirty || !isValid || blockDiv}>Update Merchant <ArrowRight /></ButtonPrimaryType2>
            </form>
        :
            <LoadingData />
        }
    </ContentBox>
  );
};

export default ManageMerchant;
