import React from "react";
import AppRouter from "../../Routers/AppRouter";
import { Container, Viewport } from "../../Utility/Utility";
import Footer from "./Footer";
import Header from "./Header";
import Menu from "./Menu";
import MenuStats from "./MenuStats";

const DashboardLayout = () => {
  return (
    <Viewport>
      <Header />
      <Container>
        <div className="lg:grid grid-cols-[250px_auto] gap-8">
          <div className="sidebar space-y-4 hidden lg:block">
            <Menu />
            <ul className="bg-[#474747] rounded-lg_next block">
              <MenuStats />
            </ul>
          </div>
          <div className="maincontent">
            <AppRouter />
          </div>
        </div>
      </Container>
      <Footer />
    </Viewport>
  );
};

export default DashboardLayout;
