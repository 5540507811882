import { useEffect, useState } from 'react';
import Chart from "react-apexcharts";
import { useUserAuth } from '../../../Contexts/authContext';
import { useCallAPI } from '../../../Hooks/callAPI';
import { ContentLoading } from '../../../Utility/Utility';

const PaymentGatewayStatistics = () => {

  const callAPI = useCallAPI();
  const auth = useUserAuth();

  const [graph1, setGraph1] = useState(false); //payments received
  const [graph2, setGraph2] = useState(false); //payments amount
  const [graph3, setGraph3] = useState(false); //payments by tokens
  const [graph4, setGraph4] = useState(false); //payments by status

  useEffect(() => {
    if(auth.appBooted) {
      getData();
    }
  }, [auth]);

  const getData = async () => {
    try {
      const res = await callAPI("merchants/getpaymentstats");
      setGraph1({
        type: "bar",
        height: 280,
        series: [
          {
            name: "Completed",
            data: res.paymentsWeeklyByStatus.completed.counts,
          },
          {
            name: "Created",
            data: res.paymentsWeeklyByStatus.created.counts,
          },
          {
            name: "Expired",
            data: res.paymentsWeeklyByStatus.expired.counts,
          },
          {
            name: "Failed",
            data: res.paymentsWeeklyByStatus.failed.counts,
          }
        ],
        options: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          title: {
            show: "Count",
          },
          dataLabels: {
            enabled: false,
          },
          colors: ["#52B86E", "#6a8dc4", "#94c20c", "#a64b4b"],
          stroke: {
            lineCap: "round",
            curve: "smooth",
          },
          markers: {
            size: 3,
          },
          xaxis: {
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            labels: {
              style: {
                colors: "#999",
                fontSize: "10px",
                fontFamily: "inherit",
                fontWeight: 400,
              },
            },
            categories: res.paymentsWeeklyData.days,
          },
          yaxis: {
            labels: {
              formatter: function (value) {
                return Math.floor(value);
              },
              style: {
                colors: "#999",
                fontSize: "9px",
                fontFamily: "inherit",
                fontWeight: 400,
              },
            },
          },
          grid: {
            show: true,
            borderColor: "#444",
            strokeDashArray: 0,
            padding: {
              top: 5,
              right: 20,
            },
          },
          fill: {
            opacity: 0.8,
          },
          tooltip: {
            theme: "dark",
          },
          legend: {
            show: true,
            labels: {
              colors: '#999'
            }
          }
        },
      });
      setGraph2({
        type: "line",
        height: 280,
        series: [
          {
            name: "Completed",
            data: res.paymentsWeeklyByStatus.completed.sums,
          },
          {
            name: "Created",
            data: res.paymentsWeeklyByStatus.created.sums,
          },
          {
            name: "Expired",
            data: res.paymentsWeeklyByStatus.expired.sums,
          },
          {
            name: "Failed",
            data: res.paymentsWeeklyByStatus.failed.sums,
          }
        ],
        options: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          title: {
            show: "Payments by Status",
          },
          dataLabels: {
            enabled: false,
          },
          colors: ["#52B86E", "#6a8dc4", "#94c20c", "#a64b4b"],
          stroke: {
            lineCap: "round",
            curve: "smooth",
          },
          markers: {
            size: 3,
          },
          xaxis: {
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            labels: {
              style: {
                colors: "#999",
                fontSize: "10px",
                fontFamily: "inherit",
                fontWeight: 400,
              },
            },
            categories: res.paymentsWeeklyData.days,
          },
          yaxis: {
            labels: {
              formatter: function (value) {
                return '$' + value;
              },
              style: {
                colors: "#999",
                fontSize: "9px",
                fontFamily: "inherit",
                fontWeight: 400,
              },
            },
          },
          grid: {
            show: true,
            borderColor: "#444",
            strokeDashArray: 0,
            padding: {
              top: 5,
              right: 20,
            },
          },
          fill: {
            opacity: 0.8,
          },
          tooltip: {
            theme: "dark",
          },
          legend: {
            show: true,
            labels: {
              colors: '#999'
            }
          }
        },
      });
      setGraph3({
        type: "donut",
        height: '280px',
        series: res.paymentsByTokens.sums,
        options: {
          labels: res.paymentsByTokens.tokenNames,
          chart: {
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            pie: {
              expandOnClick: true
            }
          },
          stroke:{
          colors:['#52B86E']
          },
          tooltip: {
            y: {
              formatter: function(value) {
                return '$' + value;
              }
            }
          },
          
          title: {
            show: "",
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: true,
            labels: {
              colors: '#999'
            }
          }
        },
      })
      setGraph4({
        type: "donut",
        height: '280px',
        series: res.paymentsByStatus.sums,
        options: {
          labels: res.paymentsByStatus.status,
          chart: {
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            pie: {
              expandOnClick: true
            }
          },
          stroke:{
          colors:['#52B86E']
          },
          tooltip: {
            y: {
              formatter: function(value) {
                return '$' + value;
              }
            }
          },
          
          title: {
            show: "",
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: true,
            labels: {
              colors: '#999'
            }
          }
        },
      });
    } catch (error) {
      console.log(error);
    }
  }
  
  return (
    <div className="h-full w-full">
      <div className='flex justify-between items-center align-top mb-4'>
        <h6 className="text-white text-lg font-bold">
          Statistics
        </h6>
      </div>
      <div className='grid grid-cols-1 xl:grid-cols-2 gap-4'>
        <div className='shadow-md px-4 bg-darker rounded-10 '>
          <h6 className="text-lighter text-sm font-bold font-sans mt-4">Payments Count</h6>
          {graph1 ? 
          <Chart {...graph1} />
            :
          <ContentLoading className='flex justify-center align-middle mb-10 mt-10' />
          }
        </div>
        <div className='shadow-md px-4 bg-darker rounded-10 '>
          <h6 className="text-lighter text-sm font-bold font-sans mt-4">Payments (in USD)</h6>
          {graph2 ? 
          <Chart {...graph2} />
            :
          <ContentLoading className='flex justify-center align-middle mb-10 mt-10' />
          }
        </div>
        <div className='shadow-md p-4 bg-darker rounded-10 '>
          <h6 className="text-lighter text-sm font-bold font-sans mb-4">Payments by Methods</h6>
          {graph3 ? 
          <Chart {...graph3} />
            :
          <ContentLoading className='flex justify-center align-middle mb-10 mt-10' />
          }
        </div>
        <div className='shadow-md p-4 bg-darker rounded-10 '>
          <h6 className="text-lighter text-sm font-bold font-sans mb-4">Payments by Status</h6>
          {graph3 ? 
          <Chart {...graph4} />
            :
          <ContentLoading className='flex justify-center align-middle mb-10 mt-10' />
          }
        </div>
      </div>
    </div>
  );
};

export default PaymentGatewayStatistics;
