import React from "react";
import { AlertTriangle } from "react-feather";

const InvalidAddress = () => {
  return (
    <div className="flex justify-center items-center gap-2 text-sm py-2 px-3 bg-red-700 text-white animate-pulse">
      <AlertTriangle size={18} />
      Connected wallet address different from merchant address
    </div>
  );
};

export default InvalidAddress;
