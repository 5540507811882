import { usePublicClient } from 'wagmi';
import { useUserAuth } from "../Contexts/authContext";
import usersAbi from '../Utility/ABI/users.json';
import ercAbi from '../Utility/ABI/erc20.json';

export const useRead = () => {

    const auth = useUserAuth();
    const client = usePublicClient();

    const readUsers = async (method, params) => {
        const res = await client.readContract({
            address: auth.systemSettings.CONTRACT_ADDRESS_USERS,
            abi: usersAbi,
            functionName: method,
            args: params
        });
        return res;
    }

    const getAllowance = async (token, owner, spender) => {
        const res = await client.readContract({
            address: token,
            abi: ercAbi,
            functionName: 'allowance',
            args: [owner, spender]
        });
        return res;
    }

    const getTokenBalance = async (token, owner) => {
        const res = await client.readContract({
            address: token,
            abi: ercAbi,
            functionName: 'balanceOf',
            args: [owner]
        });
        return res;
    }

    return { readUsers, getAllowance, getTokenBalance };
}