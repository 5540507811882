import React from "react";
import s1 from "../../Assets/s-1.png";
import s2 from "../../Assets/s-2.png";
import s3 from "../../Assets/s-3.png";
import s4 from "../../Assets/s-4.png";
import BulkImgTitleDesc from "../auth/BulkImgTitleDesc";
import { Container, Viewport } from "../../Utility/Utility";
import Footer from "./Footer";
import Header from "./Header";
import AppRouter from "../../Routers/AppRouter";

const AuthLayout = () => {
  return (
    <Viewport>
      <Header />
      <Container>
        <div className="pt-8 pb-8 xl:pt-16 space-y-14 xl:space-y-[76px] max-w-[1120px] mx-auto">
          <div className="welcome-wrapper text-center">
            <h1 className="text-primary text-4xl md:text-5xl xl:text-[64px] font-aleo font-bold leading-tight">
              Welcome to Dexloot
            </h1>
            <p className="text-white text-lg md:text-[22px] font-aleo font-normal lg:mt-2">
              Decentralised Payment & Payout system for your business
            </p>
            <AppRouter />
          </div>
          <div>
            <h3 className="text-primary text-xl sm:text-2xl font-aleo font-bold mb-6 lg:mb-12 xl:mb-[60px] text-center">
              How are we different?
            </h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-10">
              <BulkImgTitleDesc
                img={s1}
                title="Instant settlement using Smart Contracts"
                description="
                  Enjoy seamless instant settlement facilitated by two key technologies: WEB3 and smart contracts. Dex3 doesn't hold any merchant funds with it."
              />
              <BulkImgTitleDesc
                img={s2}
                title="Get 100% settlement to your wallet with 0 merchant fees"
                description="We proudly offer a seamless 100% settlement process, completely devoid of any merchant fees."
              />
              <BulkImgTitleDesc
                img={s3}
                title="No KYB or merchant approval required"
                description="Instant activation process. Merchants can access Dex3 platform without undergoing KYB verification or merchant approval processes."
              />
              <BulkImgTitleDesc
                img={s4}
                title="Global Reach - No restrictions on where you can use and where not"
                description="Expand your business horizons effortlessly with Dex3 solution, offering unparalleled global reach to connect you with customers worldwide"
              />
            </div>
          </div>
        </div>
      </Container>
      <Footer />
    </Viewport>
  );
};

export default AuthLayout;
