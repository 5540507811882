import { PuffLoader } from "react-spinners";
import { Container, Viewport } from "../Utility/Utility";

export default function LayoutLoading() {  
    return (
        <Viewport>
            <Container>
                <div className="grid h-screen place-items-center bg-gradient-one">
                    <PuffLoader
                        color={'#52B86E'}
                        loading={true}
                        size={70}
                    />
                </div>
            </Container>
        </Viewport>
    );
}