import { Alert } from '@material-tailwind/react';
import React, { useState } from "react";
import { AlertCircle } from 'react-feather';
import { useNavigate } from "react-router-dom";
import { useUserAuth } from '../../../Contexts/authContext';
import { useCallAPI } from "../../../Hooks/callAPI";
import { useDeviceData } from '../../../Hooks/deviceData';
import { useHelpers } from '../../../Hooks/helpers';
import { useWrite } from '../../../Hooks/writeContract';
import { ButtonPrimaryGradient } from "../../../Utility/Buttons";
import { ContentBox } from "../../../Utility/Utility";

const ShowMerchantKeys = () => {

  const callAPI = useCallAPI();
  const device = useDeviceData();
  const writeContract = useWrite();
  const helper = useHelpers();

  const [keys, setKeys] = useState(false);
  const [blockDiv, setBlockDiv] = useState(false);

  const requestKeys = async () => {
    try {
      setBlockDiv(true);
      const message = device.getToken();
      const hash = await writeContract.SignMessage(message);
      const res = await callAPI("merchants/getkeys", { signature: hash });
      setKeys(res);
      setBlockDiv(false);
    } catch (error) {
      setBlockDiv(false);
    }
  }

  return (
    <ContentBox>
        {keys ?
        <>
            {keys.merchant_public ? 
              <div className='max-w-lg my-10 sm:my-10 mx-auto space-y-5'>
                  <h2 className='font-bold text-primary text-2xl'>API Keys</h2>
                    <Alert color='purple' className='text-sm'>API keys are same for both Payment Gateway and Payout System. Use with caution. Do not share with anyone.</Alert>
                  <div className='border border-primary rounded-10 px-5 py-3'>
                    <h4 className='text-light text-md'>Merchant Public Key</h4>
                    <p className='text-xs sm:text-lg font-bold text-primary'><helper.CopyText value={keys.merchant_public} iconSize='20' /></p>
                  </div>
                  <div className='border border-primary rounded-10 px-5 py-3'>
                    <h4 className='text-light text-md'>Merchant Private Key</h4>
                    <p className='text-xs sm:text-lg font-bold text-primary'><helper.CopyText value={keys.merchant_private} iconSize='20' /></p>
                  </div>
              </div>
            :
              <div className='max-w-md my-10 sm:my-20 mx-auto text-center space-y-5'>
                <AlertCircle size={80} className='text-red-400 mx-auto' />
                <h2 className='font-bold text-red-400 text-2xl'>Complete Merchant Setup</h2>
                <p className='text-lighter'>Complete merchant account setup first. Then come back here to view your Merchant API Keys</p>
            </div>
            }
          </>
        :
            <div className='max-w-md my-10 sm:my-20 mx-auto text-center space-y-5'>
                <h2 className='font-bold text-primary text-2xl'>Verify Wallet Ownership</h2>
                <p className='text-lighter'>To view your API keys, please verify wallet ownership. Make sure you are connected to your merchat registered wallet address.</p>
                <ButtonPrimaryGradient disabled={blockDiv} onClick={requestKeys}>Verify Ownership</ButtonPrimaryGradient>
            </div>
        }
    </ContentBox>
  );
};

export default ShowMerchantKeys;
