const runDevAsProd = false;
const settings = {
    useDevServer: (process.env.NODE_ENV === 'development' && !runDevAsProd)?true:false //Change to false for using production servers
}

const appConfig = {
    version: '1.2.3',
    appName: 'Dex3',
    appDescription: 'Dex3 - Web3 Payment Gateway',
    appLink: 'https://app.dex3.io',
    payLink: 'https://pay.dex3.io/pay/',
    payoutLink: 'https://pay.dex3.io/payout/',
    websiteLink: 'https://www.dex3.io/',

    gaID: "G-CTDVL8W4LS",
    walletConnectKey: '46a15ee7f875d7527db58907cceb93af',
    apiEndpoint: settings.useDevServer?'http://localhost:6861/api/':'https://api.dex3.io/api/',

    //URLs
    support: 'https://dex3.freshdesk.com/support/home',

    //Documentation links : 
    doc_main: 'https://docs.dex3.io/',
    doc_webhook: 'https://docs.dex3.io/',
}

export default appConfig;