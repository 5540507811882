import React from "react";
import IconDoller from "../../Assets/doller.png";
import Stats from "./Stats";

const MenuStats = () => {
  return (
    <>
      {/* <li className="border-b border-[#313131] last:border-0 px-6 py-5">
        <Stats
          icon={IconDoller}
          label="Payments"
          value="$124<span class='sub'>(24)</span>"
          subLabel="$124 (10) today"
        />
      </li>
      <li className="block px-6 py-5">
        <Stats
          icon={IconDoller}
          label="Payments"
          value="$124<span class='sub'>(24)</span>"
          subLabel="$124 (10) today"
        />
      </li> */}
    </>
  );
};

export default MenuStats;
