import React from "react";
import appConfig from "../../Utility/AppConfig";
import { ButtonGray } from "../../Utility/Buttons";

const Footer = () => {
  return (
    <div className="footer mt-auto flex flex-col-reverse md:flex-row items-center justify-between px-4 py-5 md:px-6 md:py-5 lg:px-8 lg:py-6">
      <p className="text-[#828282] text-sm font-normal mt-4 md:mt-0">
        Copyright © 2024 Dex3.io | v{appConfig.version}
      </p>
      <a href={appConfig.support} target="blank">
        <ButtonGray>
          Contact Us
        </ButtonGray>
      </a>
    </div>
  );
};

export default Footer;
