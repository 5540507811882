import { Drawer } from "@material-tailwind/react";
import React from "react";
import { X } from "react-feather";
import Menu from "./Menu";
import MenuStats from "./MenuStats";
import { useUserAuth } from "../../Contexts/authContext";

const DrawerMenu = ({ openDrawer, onCloseDrawer }) => {
  const auth = useUserAuth();
  return (
    <Drawer open={openDrawer} onClose={onCloseDrawer} className="p-4 bg-[#282828]" placement="right">
      <div className="flex justify-end mb-3">
        <X onClick={onCloseDrawer} className="text-white"/>
      </div>
      <div className="sidebar space-y-5 mb-4" onClick={onCloseDrawer}>
        <div className="flex justify-center"><w3m-button /></div>
        {auth.loggedIn && 
          <>
            <Menu />
            <ul className="bg-[#474747] rounded-lg_next block">
              <MenuStats />
            </ul>
          </>
        }
      </div>
    </Drawer>
  );
};

export default DrawerMenu;
