import { createWeb3Modal, useWeb3Modal } from "@web3modal/wagmi/react";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { useAccount, useDisconnect } from "wagmi";
import IconLogo from "../../Assets/logo.png";
import IconWallet from "../../Assets/wallet.png";
import { useUserAuth } from "../../Contexts/authContext";
import { useDeviceData } from "../../Hooks/deviceData";
import { useHelpers } from "../../Hooks/helpers";
import { ButtonGray } from "../../Utility/Buttons";
import DrawerMenu from "./DrawerMenu";
import Logo from "./Logo";
import UserInfo from "./UserInfo";

const Header = () => {
  
  const account = useAccount();
  const web3Modal = useWeb3Modal();
  const disconnect = useDisconnect();
  const device = useDeviceData();
  const helper = useHelpers();
  const navigate = useNavigate();
  const auth = useUserAuth();

  const [drawer, setDrawer] = useState(false);

  useEffect(() => {
    if(disconnect.isSuccess) {
      disconnectWallet();
    }
  }, [disconnect])

  const disconnectWallet = async () => {
      device.deleteToken();
      window.location.replace('/login');
  }

  return (
    <header className="header bg-transparent px-4 py-[14px] md:px-6 md:py-5 lg:px-8 lg:py-6">
      <DrawerMenu openDrawer={drawer} onCloseDrawer={() => setDrawer(false)} />
      <div className="flex items-center justify-between">
        <Logo logo={IconLogo} />
        <div className="hidden md:flex space-x-10">
          <w3m-button />
        </div>
        <button
          onClick={() => setDrawer(true)}
          className="mobile-bar ml-auto md:hidden"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#FCDF8F"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="w-9 h-9 transition duration-300"
          >
            <line x1="3" y1="12" x2="21" y2="12"></line>
            <line x1="3" y1="6" x2="21" y2="6"></line>
            <line x1="3" y1="18" x2="21" y2="18"></line>
          </svg>
        </button>
      </div>
    </header>
  );
};

export default Header;
