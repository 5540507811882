import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createWeb3Modal } from '@web3modal/wagmi/react';
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter as Router } from "react-router-dom";
import { WagmiProvider, http } from 'wagmi';
import { bsc, bscTestnet, mainnet, polygon, polygonMumbai, sepolia } from 'wagmi/chains';
import ScrollToTop from './Contexts/ScrollToTop';
import { AppStateProvider } from './Contexts/appStateContext';
import { UserAuthProvider } from './Contexts/authContext';
import Layout from './Routers/Layout';
import appConfig from './Utility/AppConfig';

function App() {
  const queryClient = new QueryClient();
  const projectId = appConfig.walletConnectKey;

  const metadata = {
    name: appConfig.appName,
    description: appConfig.appDescription,
    url: appConfig.appLink,//appConfig.appLink, // origin must match your domain & subdomain
    icons: ['https://i.ibb.co/GcgDhXt/favicon.png']
  };

  const chains = [ mainnet, bsc, polygon, sepolia, bscTestnet, polygonMumbai ]
  const config = defaultWagmiConfig({
    chains, // required
    projectId, // required
    metadata, // required
    enableWalletConnect: true, // Optional - true by default
    enableInjected: true, // Optional - true by default
    enableEIP6963: true, // Optional - true by default
    enableCoinbase: true, // Optional - true by default
    // enableEmail: true
    transports: {
      [mainnet.id]: http('https://long-palpable-tab.quiknode.pro/799a263683019be57ba4d0d67f77760453f28503/'),
      [sepolia.id]: http('https://ancient-light-bridge.ethereum-sepolia.quiknode.pro/98a6eb962fefd9812217b3112400f18084cce002/'),
      [bsc.id]: http('https://quaint-alien-valley.bsc.quiknode.pro/4e97660451fc9060ebd399536f8d3e0925cb414f/'),
      [bscTestnet.id]: http('https://bold-misty-thunder.bsc-testnet.quiknode.pro/2e47f29249ec2044358b04e737e2438b16a753ea/'),
      [polygon.id]: http('https://orbital-omniscient-gadget.matic.quiknode.pro/be7f39caa9a16443a01259cd7cd20eb8ee76cc93/'),
      [polygonMumbai.id]: http('https://patient-soft-road.matic-testnet.quiknode.pro/fe7e98aa8a9b6372ccf00cdc1fa74261f0b2a9de/'),
    }
  });

  createWeb3Modal({
    wagmiConfig: config,
    projectId,
    enableAnalytics: true, // Optional - defaults to your Cloud configuration
    themeVariables: {
      "--w3m-accent": '#52B86E'
    }
  });

  return (
    <AppStateProvider>
      <UserAuthProvider>
        <WagmiProvider config={config}>
          <QueryClientProvider client={queryClient}>
            <Router>
              <ScrollToTop />
              <Layout />
            </Router>
            <Toaster position="top-right" />
          </QueryClientProvider>
        </WagmiProvider>
      </UserAuthProvider>
    </AppStateProvider>
  );
}
export default App;
