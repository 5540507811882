import React, { useState } from 'react';
import { useAccount } from 'wagmi';
import { useUserAuth } from '../../Contexts/authContext';
import { useCallAPI } from '../../Hooks/callAPI';
import { useDeviceData } from '../../Hooks/deviceData';
import { useHelpers } from '../../Hooks/helpers';
import { useWrite } from '../../Hooks/writeContract';
import {
  ButtonPrimary
} from "../../Utility/Buttons";

const Login = () => {

    const callAPI = useCallAPI();
    const auth = useUserAuth();
    const account = useAccount();
    const writeContract = useWrite();
    const device = useDeviceData();
    const helper = useHelpers();

    const [loading, setLoading] = useState(false);

    const loginNow = async () => {
      try {

        setLoading(true);
        const nonceRes = await callAPI("auth/getNonce", { address: account.address });
        const signature = await writeContract.SignMessage(nonceRes.nonce, account.address);
        
        const res = await callAPI("auth/verifyLogin", { address: account.address, hash: signature });
        device.setToken(res.token);
        await auth.bootApp();
        setLoading(false);

      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
    
    return (
      <div className="mt-6 lg:mt-10">
        {account.isConnected ?
          <div className='flex justify-center'>
            <div>
              <ButtonPrimary loading={loading} onClick={loginNow}>
                Access Dashboard Now
              </ButtonPrimary>
              <p className='text-xs mt-4 text-gray-300'>Connected to <span className='text-primary font-bold'><helper.trimAddress address={account.address} copy /></span></p>
            </div>
          </div>
        :
          <div className='flex justify-center'>
            <w3m-button />
          </div>
        }
      </div>
    );
};

export default Login;
