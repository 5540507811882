import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from "react";
import { ArrowRight } from "react-feather";
import { useForm } from "react-hook-form";
import toast from 'react-hot-toast';
import { useAccount } from 'wagmi';
import * as Yup from 'yup';
import { useUserAuth } from '../../../Contexts/authContext';
import { useCallAPI } from "../../../Hooks/callAPI";
import { useDeviceData } from '../../../Hooks/deviceData';
import { useWrite } from '../../../Hooks/writeContract';
import { ButtonPrimaryType2 } from "../../../Utility/Buttons";
import { ChainDark } from '../../../Utility/Chains';
import { FormInput } from '../../../Utility/Inputs';
import { ContentBox, ContentLoading } from "../../../Utility/Utility";
import { Alert, Switch } from '@material-tailwind/react';
import appConfig from '../../../Utility/AppConfig';
import ManagePayoutSystemAllowance from './PayoutAllowance';

const ManagePayoutSystem = () => {

  const callAPI = useCallAPI();
  const auth = useUserAuth();
  const device = useDeviceData();
  const writeContract = useWrite();
  const account = useAccount();

  const [tokens, setTokens] = useState(false);
  const [selectedTokens, setSelectedTokens] = useState([]);
  const [showTokens, setShowTokens] = useState([]);
  const [showTestTokens, setShowTestTokens] = useState(false);
  const [vaultAddr, setVaultAddr] = useState(false);

  const [formError, setFormError] = useState({});
  const [blockDiv, setBlockDiv] = useState(false);

  useEffect(() => {
    if(auth.appBooted) {
      getData();
    }
  }, [auth]);

  const getData = async () => {
    try {
      const res = await callAPI("merchants/getPayout");
      setTokens(res.tokens);
      showTestnetTokens(res.tokens);

      //Set default form values here 
      if(res.merchant.id) {
        setSelectedTokens(JSON.parse(res.merchant.tokensAccepted));
        reset({
          vaultAddress: res.merchant.vaultAddress
        });
        setVaultAddr(res.merchant.vaultAddress);
      }

    } catch (error) {
      console.log(error);
    }
  }

  const validationSchema = Yup.object().shape({
    vaultAddress: Yup.string().matches(/^(0x)?[0-9a-fA-F]{40}$/, 'Invalid Wallet address').required('Please enter vaultaAddress'),
  });

  const {
      register, formState: { errors, isValid, isDirty }, handleSubmit, getValues, setValue, control, watch, reset
  } = useForm({ mode: 'onChange', resolver: yupResolver(validationSchema) })


  const submitForm = async () => {

    try {

      let finalTokens = selectedTokens;
      if(!showTestTokens) {
        finalTokens = selectedTokens.filter(token => {
          return tokens.find(obj => obj.id === token).isTestnet === 'no';
        });
      }

      setBlockDiv(true);
      setFormError({});

      if(finalTokens.length === 0) {
        toast.error('Select atleast one token');
        return;
      }
      
      const formData = getValues();
      formData.tokensAccepted = finalTokens;
      formData.address = account.address;

      const message = JSON.stringify(formData.tokensAccepted) + formData.vaultAddress + device.getToken();
      const sigHash = await writeContract.SignMessage(message);

      formData.hash = sigHash;
      await callAPI("merchants/setPayout", formData);
      toast.success("Payout Settings updated.");
      await getData();
      setBlockDiv(false);
      
    } catch (error) {
      console.log(error);
      setBlockDiv(false);
    }

  }

  const handleCustomSelect = (itemId, arr, setFn) => {
    if(arr.includes(itemId)) {
      setFn(arr.filter((id) => id !== itemId));
    } else {
      setFn([...arr, itemId]);
    }
  };

  const showTestnetTokens = (tokens, show) => {
    setShowTokens(show ? tokens : tokens.filter(item => item.isTestnet === 'no'));
    setShowTestTokens(show);
  }

  return (
    <ContentBox>
      <Alert color='indigo' variant='gradient' className='text-sm mb-5'>With our payout system, merchants can set their own vault addresses loaded with assets that you choose to pay your customers. With payouts handled directly by our smart contract, enjoy instant and hassle-free payouts to your customers. </Alert>
      <div className='space-y-4'>
        <Alert color='amber' variant='gradient' className='text-sm mb-5'>Merchants can store funds inside your own wallet (Vault Address). Please keep in mind, When you choose the preferred payout tokens, make sure to provide sufficient allowance to {appConfig.appName} contract for each token.</Alert>
        <FormInput name='vaultAddress' label="Vault Address" helper="Enter you wallet address where you have stored your payout tokens" reg={register} formerror={formError} errors={errors} isdirty={isDirty ? 1 : 0} />
        {tokens && tokens.length > 0 ?
          <div>
            <h6 className="text-white text-sm font-normal mb-5 flex justify-between items-center gap-4">
              Choose Tokens to accept
              <div className='border border-primary px-4 py-2 rounded-10 flex justify-between gap-5 items-center'>
                <p className='text-sm'>Show Testnet Tokens</p>
                <Switch onChange={(e) => showTestnetTokens(tokens, e.target.checked)} defaultChecked={false} color='green' />
              </div>
            </h6>
            {showTestTokens && <Alert color='amber' variant='gradient' className='text-sm mb-6'>Use testnet tokens only for Testing purpose. Testnet payments can trigger webhook too, so do not use them in Production environment.</Alert>}
            <div className="flex flex-wrap gap-3 sm:gap-5">
              {showTokens.length > 0 ? showTokens.map((e,i) => {
                return (
                  <div key={i} onClick={() => handleCustomSelect(e.id, selectedTokens, setSelectedTokens)}>
                    <ChainDark
                      icon={e.logo}
                      name={e.name}
                      isActive={selectedTokens.includes(e.id)}
                    />
                  </div>
                )
              }) : <ContentLoading className='my-5' />}
            </div>
          </div>
        : 
          <ContentLoading />
        }
        <ButtonPrimaryType2 onClick={submitForm} disabled={blockDiv ? 1 : 0}>Update Settings <ArrowRight /></ButtonPrimaryType2>
      </div>

      {tokens && <ManagePayoutSystemAllowance allTokens={tokens} vaultAddr={vaultAddr} />}
      
    </ContentBox>
  );
};

export default ManagePayoutSystem;
