import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import CreateNewPayment from "../../Components/dash/paymentGateway/Create";
import PaymentGatewayStatistics from "../../Components/dash/paymentGateway/Statistics";
import TransactionsPaymentGateway from "../../Components/dash/paymentGateway/Transactions";
import { ButtonGray, ButtonPrimaryGradient } from "../../Utility/Buttons";
import { PrimaryHeading } from "../../Utility/Headings";
import { ContentBox, LoadingData } from "../../Utility/Utility";

const PaymentGateway = () => {

  const location = useLocation();
  const params = useParams();

  const [ShowView, setShowView] = useState(null);
  
  useEffect(() => {
    switch (params.view) {
      case 'stats':
        setShowView(<PaymentGatewayStatistics />);
        break;
  
      case 'transactions':
        setShowView(<TransactionsPaymentGateway />);
        break;

      case 'create':
        setShowView(<CreateNewPayment />);
        break;
    
      default:
        break;
    }
  }, [location.pathname])

  return (
    <div className="space-y-6 sm:space-y-4">
      <div className="">
        <PrimaryHeading>Payment Gateway</PrimaryHeading>
      </div>
      <div className="tabs flex flex-wrap gap-3">
        <HandleMenu name='Statistics' link='/paymentGateway/stats' />
        <HandleMenu name='Transactions' link='/paymentGateway/transactions' />
        <HandleMenu name='Create Payment' link='/paymentGateway/create' />
      </div>
      <ContentBox>
        <div className="">
          {ShowView !== null ? <>{ShowView}</> : <LoadingData />}
        </div>
      </ContentBox>
    </div>
  );
};

const HandleMenu = ({ name, link }) => {
  const location = useLocation();
  return (
    <div>
    {location.pathname.includes(link) ?
      <Link to={link}><ButtonPrimaryGradient>{name}</ButtonPrimaryGradient></Link>
    :
      <Link to={link}><ButtonGray>{name}</ButtonGray></Link>
    }
    </div>
  );
}

export default PaymentGateway;
