import { useEffect, useState } from 'react';
import { useCallAPI } from './callAPI';
import { IconButton } from '@material-tailwind/react';

const useDynamicTable = (api, fromDate, toDate, searchFilters) => {

	const callAPI = useCallAPI();

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			try {
				const res = await callAPI(api, {
					params: {
						page: currentPage,
						pageSize: 10, // Adjust page size as needed
						fromDate: fromDate,
						toDate: toDate,
						...searchFilters,
					}
				});
				setData(res.result.rows);
				setTotalPages(res.result.totalPages);
				setLoading(false);
			} catch (error) {
				console.error('Error fetching data:', error);
				setLoading(false);
			}
		};

		fetchData();

		// Cleanup function
		return () => {
		// Cleanup logic if needed
		};
	}, [currentPage, searchFilters]);

	const handlePageChange = (page) => {
		setCurrentPage(page);
	};

	return { data, loading, currentPage, totalPages, handlePageChange };
};

export const DynamicPagination = ({ totalPages, currentPage, handlePageChange }) => {
	return (
		<div className="p-4">
			<div className="flex flex-wrap justify-center items-center gap-2">
				{Array.from({ length: totalPages }, (_, index) => index + 1).map(page => (
					<IconButton key={page} onClick={() => handlePageChange(page)} variant={currentPage === page ? 'gradient':'outlined'} color='green' size="sm">{page}</IconButton>
				))}
			</div>
		</div>
	);
}

export default useDynamicTable;
