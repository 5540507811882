import { Alert, Button, IconButton } from '@material-tailwind/react';
import React, { useEffect, useState } from "react";
import { MinusCircle, PlusCircle } from "react-feather";
import { formatUnits } from 'viem';
import { useAccount } from 'wagmi';
import { useHelpers } from '../../../Hooks/helpers';
import { useRead } from '../../../Hooks/readContract';
import { useSwitchWalletData } from '../../../Hooks/switchWalletData';
import { useWrite } from '../../../Hooks/writeContract';
import ercAbi from '../../../Utility/ABI/erc20.json';
import appConfig from '../../../Utility/AppConfig';
import { ContentLoading } from "../../../Utility/Utility";
import { useUserAuth } from '../../../Contexts/authContext';
import toast from 'react-hot-toast';

const ManagePayoutSystemAllowance = ({ allTokens, vaultAddr }) => {

  const writeContract = useWrite();
  const auth = useUserAuth();
  const readContract = useRead();
  const account = useAccount();
  const helper = useHelpers();
  const switchWalletData = useSwitchWalletData();

  const [tokenBalance, setTokenBalance] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getTokenBalances();
  }, []);

  const getTokenBalances = async () => {
    if(allTokens.length === 0) return;
    let resultsArr = [];

    // Use map to create an array of promises
    let promises = allTokens.map(async (t) => {
        let allowance = 0;
        let balance = 0;
        try {
            // Get allowance
            allowance = await readContract.getAllowance(t.address, vaultAddr, t.payouts_contract);
            allowance = formatUnits(allowance, t.decimals);
            // Get balance
            balance = await readContract.getTokenBalance(t.address, vaultAddr);
            balance = formatUnits(balance, t.decimals);
          } catch (error) {
            console.log(error);
        }
        // Return an object containing both allowance and balance
        return { allowance, balance };
    });

    // Use Promise.all to wait for all promises to resolve
    Promise.all(promises)
    .then((results) => {
        // results is an array of objects, each containing allowance and balance for a token
        resultsArr = results;
        // Assuming setTokenBalance is a function to update your state or UI
        // You might need to adjust this part based on your actual implementation
        setTokenBalance(resultsArr);
    })
    .catch((error) => {
        console.error('An error occurred:', error);
    });

  }

  const approveTokens = async (t, revoke = false) => {
    
    try {

      if(account.address.toLowerCase() !== vaultAddr.toLowerCase()) {
        toast.error('Switch connected wallet to vault address.');
        return;
      }

      if(await switchWalletData.chain(t.chain_id)) {
        toast.success('Network Switched. Approve/Revoke now');
        return;
      }

      setLoading(t.id);
      const res = await writeContract.ContractWrite(t.address, ercAbi, 'approve', [ t.payouts_contract, revoke ? 0 : '115792089237316195423570985008687907853269984665640564039457584007913129639935' ], 0, vaultAddr);
      await getTokenBalances();
      setLoading(false);
      return res;

    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  let tableHead = [
    <>Token</>, 
    <>Balance</>,
    <>Allowed</>,
    <>Allow/Revoke all</>
  ];

  return (
    <>
      <Alert color='indigo' variant='gradient' className='text-sm mt-5 mb-5'>Provide allowance to {appConfig.appName}'s contract to securely use your tokens to instantly pay your customers. You can choose how many tokens you want to allow. We recommend setting max allowance to ensure uninterrupted payout experience. </Alert>
      <Alert color='teal' variant='gradient' className='text-sm mt-5 mb-5'>If you wish to manually provide allowance (via a smart contract or within your wallet). Make sure to allow only {appConfig.appName}'s contract mentioned in the official documentation. Allowing any other contract other than mentioned in the docs will result in loss of funds. <a href='https://docs.dex3.io/payout-system/payout-contracts' target='blank' className='mt-2 text-md block'><Button size='sm' color='amber'>View docs</Button></a></Alert>
      <div className="w-full overflow-scroll px-0 table-custom">
        <table className="w-full">
          <thead>
            <tr>
              {tableHead.map((head, key) => (
                <th key={key} className="">{head}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {allTokens.length > 0 && allTokens.map(
              (d, index) => {
                const classes =  "p-4";
                return (
                  <tr key={d.id}>
                    <td className='flex justify-start gap-2 items-center'>
                      <img src={d.logo} alt="icon" className="h-[30px]" />
                      {d.name}
                    </td>
                    <td className={classes}>
                      {tokenBalance[index] ? <helper.AmountToCurrency amount={tokenBalance[index].balance || 0} suffix={' ' + d.symbol} prefix='' /> : <ContentLoading loaderSize={15} />}
                    </td>
                    <td className={classes}>
                      {tokenBalance[index] ? <helper.AmountToCurrency amount={tokenBalance[index].allowance || 0} suffix={' ' + d.symbol} prefix='' /> : <ContentLoading loaderSize={15} />}
                    </td>
                    <td className='space-x-2'>
                      {loading === d.id ?
                        <ContentLoading loaderSize={15} />
                      :
                        <>
                          <IconButton onClick={() => approveTokens(d, false)} size='sm' color='amber' variant='gradient'><PlusCircle size={17} /></IconButton>
                          <IconButton onClick={() => approveTokens(d, true)} size='sm' color='red' variant='gradient'><MinusCircle size={17} /></IconButton>
                        </>
                      }
                    </td>
                  </tr>
                );
              },
            )}
            {(!allTokens || allTokens.length === 0) && 
              <tr>
                <td colSpan={tableHead.length}><div className='flex justify-center pt-3 opacity-50'>Enable tokens to start approving</div></td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ManagePayoutSystemAllowance;
