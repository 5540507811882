import React from "react";
import { Link } from "react-router-dom";
import payment from '../../Assets/payment.png';
import payout from '../../Assets/payout.png';
import wavehand from '../../Assets/waving-hand.png';
import { useUserAuth } from "../../Contexts/authContext";
import { ButtonPrimary } from "../../Utility/Buttons";
import { PrimaryHeading } from "../../Utility/Headings";
import { ContentBox, LoadingData } from "../../Utility/Utility";

const Dashboard = () => {

  const auth = useUserAuth();

  return (
    <div>
      <div className="mb-4 lg:mb-6">
        <PrimaryHeading>Dashboard</PrimaryHeading>
      </div>
      {(auth && auth.loggedIn) ?
        <>
          {!auth.appData.merchant.email && 
            <ContentBox>
              <div className="min-h-[300px] text-center flex flex-col items-center justify-center">
                <img src={wavehand} className="w-[60px] mb-5" alt="" />
                <h6 className="text-white text-base lg:text-lg font-bold mb-1">
                  Welcome, Create a Merchant now
                </h6>
                <p className="text-gray-300 mb-5">Instant Creation.<br/>No KYB or approval required</p>
                <div className="">
                  <Link to='/manage/merchant'>
                    <ButtonPrimary>
                      Create Merchant
                    </ButtonPrimary>
                  </Link>
                </div>
              </div>
            </ContentBox>
          }
          {auth.appData.merchant.email && 
            <ContentBox>
              <div className="min-h-[300px] grid grid-cols-1 md:grid-cols-2 gap-20 items-start justify-center py-10">
                <div className="text-center flex flex-col px-4 md:px-10">
                  <img src={payout} className="w-[80px] mx-auto mb-4" alt="" />
                  <h6 className="text-white text-base lg:text-lg font-bold mb-1">
                    Accept Multi-Chain Crypto Payments 
                  </h6>
                  <p className="text-gray-300 mb-5">Multi-chain and multi-currency payments with self-hosted solution. Just plug and get paid.</p>
                  <div className="">
                    <Link to='/manage/paymentgateway'>
                      <ButtonPrimary>
                        Manage
                      </ButtonPrimary>
                    </Link>
                  </div>
                </div>
                <div className="text-center flex flex-col px-4 md:px-10">
                  <img src={payment} className="w-[80px] mx-auto mb-4" alt="" />
                  <h6 className="text-white text-base lg:text-lg font-bold mb-1">
                    Handle Customer Payouts. 
                  </h6>
                  <p className="text-gray-300 mb-5">Self-hosted payout system. Let your customers claim. You pay $0 in fee.</p>
                  <div className="">
                    <Link to='/manage/payoutsystem'>
                      <ButtonPrimary>
                        Manage
                      </ButtonPrimary>
                    </Link>
                  </div>
                </div>
              </div>
            </ContentBox>
          }
        </>
      : 
        <LoadingData />
      }
      
    </div>
  );
};
export default Dashboard;
