import { yupResolver } from '@hookform/resolvers/yup';
import { Alert } from '@material-tailwind/react';
import React, { useState } from "react";
import { ArrowRight } from "react-feather";
import { useForm } from "react-hook-form";
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { useCallAPI } from "../../../Hooks/callAPI";
import { useDeviceData } from '../../../Hooks/deviceData';
import { useHelpers } from '../../../Hooks/helpers';
import { useWrite } from '../../../Hooks/writeContract';
import { ButtonPrimaryType2 } from "../../../Utility/Buttons";
import { FormInput } from '../../../Utility/Inputs';
import { ContentBox } from "../../../Utility/Utility";

const CreateNewPayment = () => {

  const helper = useHelpers();
  const device = useDeviceData();
  const writeContract = useWrite();
  const callAPI = useCallAPI();

  const [formError, setFormError] = useState({});
  const [blockDiv, setBlockDiv] = useState(false);
  const [paylink, setPaylink] = useState(false);

  const validationSchema = Yup.object().shape({
      order_id: Yup.string()
        .required('Order ID is required'),
      productName: Yup.string()
          .required('Please enter your Merchant Name')
          .min(1, "Please enter atleast 1 characters")
          .max(50, "Please keep product name under 50 characters"),
      amount: Yup.number('Invalid Number').test("decimals", "Invalid amount", (value) => {
            try {
              return !isNaN(value);
            } catch (error) {
              return false;
            }
          }),
      expiration: Yup.number().required('Expiration is required'),
      notes: Yup.string().test("json", "Invalid JSON format", (value) => {
            try {
              if(value === '') return true;
              JSON.parse(value);
              return true;
            } catch (error) {
              return false;
            }
          }).optional().nullable(),
      webhook: Yup.string().url('Invalid URL').optional().nullable(),
      success_redirect: Yup.string().url('Invalid URL').optional().nullable(),
      cancel_redirect: Yup.string().url('Invalid URL').optional().nullable()
  });

  const {
      register, formState: { errors, isValid, isDirty }, handleSubmit, getValues, control, watch
  } = useForm({ mode: 'onChange', resolver: yupResolver(validationSchema) })

  const onSubmit = async () => {

    try {

      setBlockDiv(true);
      setFormError({});
      
      const formData = getValues();
      formData.amount = formData.amount*100;
      
      formData.webhook === '' && delete formData.webhook; 
      formData.success_redirect === '' && delete formData.success_redirect; 
      formData.cancel_redirect === '' && delete formData.cancel_redirect; 
      formData.notes === '' && delete formData.notes; 

      const message = formData.order_id + formData.amount + formData.expiration + device.getToken();
      const hash = await writeContract.SignMessage(message);
      formData.hash = hash;

      const res = await callAPI("merchants/createpayment", formData);
      toast.success("Payment Created Successfully.");
      setPaylink(res.link);
      setBlockDiv(false);

    } catch (error) {
      console.log(error);
      if(error.code && error.code === 403) { //Handle form error
        setFormError(error.errData);
      }
      setBlockDiv(false);
    }
  }

  return (
    <ContentBox>
      <form onSubmit={handleSubmit(onSubmit)} className='space-y-4 sm:space-y-0'>
          <div className=''>
            <Alert color='indigo' variant='gradient' className='text-sm mb-5'>Create a payment link by entering the details below. Share the generated payment link with your customer to accept payment.</Alert>
            <Alert color='amber' variant='gradient' className='text-sm mb-5'>You can accept only one payment per payment link or order id. Use our APIs to generate payment links programatically from your servers for each order unlimited times.</Alert>
          </div>
          <FormInput name='order_id' label="Order ID" placeholder="1234" helper="Enter a unique Order ID" reg={register} formerror={formError} errors={errors} isdirty={isDirty ? 1 : 0} />
          <FormInput name='productName' label="Product Name" placeholder="Acme" helper="Enter Product Name" reg={register} formerror={formError} errors={errors} isdirty={isDirty ? 1 : 0} />
          <FormInput name='amount' label="Amount" placeholder="12.50" helper="Enter amount in USD" reg={register} formerror={formError} errors={errors} isdirty={isDirty ? 1 : 0} />
          <FormInput name='expiration' label="Expiration in seconds" placeholder="86400" helper="How long do you want to keep the payment link active. Enter 0 for no expiry" reg={register} formerror={formError} errors={errors} isdirty={isDirty ? 1 : 0} />
          <FormInput name='webhook' label="Webhook URL" placeholder="https://www.example.com" helper="Enter your Webhook Callback URL  (Optional)" reg={register} formerror={formError} errors={errors} isdirty={isDirty ? 1 : 0} />
          <FormInput name='success_redirect' label="Success Redirect URL" placeholder="https://www.example.com" helper="Enter your Success Redirect URL  (Optional)" reg={register} formerror={formError} errors={errors} isdirty={isDirty ? 1 : 0} />
          <FormInput name='cancel_redirect' label="Cancel Redirect URL" placeholder="https://www.example.com" helper="Enter your Cancel Redirect URL  (Optional)" reg={register} formerror={formError} errors={errors} isdirty={isDirty ? 1 : 0} />
          <FormInput name='notes' label="Custom Notes" placeholder='{"user_id":"29"}' helper="Enter custom notes that will be sent via callback. JSON only" reg={register} formerror={formError} errors={errors} isdirty={isDirty ? 1 : 0} />
          <ButtonPrimaryType2 type="submit" disabled={!isDirty || !isValid || blockDiv}>Create Payment <ArrowRight /></ButtonPrimaryType2>
          {paylink && 
            <div>
              <Alert color='light-blue' variant='gradient' className='text-sm mt-5'>
                Payment link generated successfully
                <a href={paylink} target='blank'>
                  <p className='font-bold text-sm sm:text-lg'><helper.CopyText value={paylink} iconSize='16' /></p>
                </a>
              </Alert>
            </div>
          }
      </form>
    </ContentBox>
  );
};

export default CreateNewPayment;
