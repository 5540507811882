import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useDeviceData } from "../../Hooks/deviceData";
import appConfig from "../../Utility/AppConfig";
import { ButtonGray, ButtonPrimaryGradient } from "../../Utility/Buttons";

const MenuItem = ({ name, link, overAllLink }) => {
  const location = useLocation();
  return (
    <div>
    {(location.pathname.includes(link) || location.pathname.includes(overAllLink)) ?
      <Link to={link}><ButtonPrimaryGradient>{name}</ButtonPrimaryGradient></Link>
    :
      <Link to={link}><ButtonGray>{name}</ButtonGray></Link>
    }
    </div>
  );
}

const Menu = () => {
  const device = useDeviceData();
  return (
    <div className="space-y-4">
      <MenuItem name='Dashboard' link='/dashboard' />
      <MenuItem name='Manage' link='/manage/merchant' overAllLink='/manage/' />
      <MenuItem name='Payment Gateway' link='/paymentGateway/stats' overAllLink='/paymentGateway' />
      <MenuItem name='Payout System' link='/payoutGateway/stats' overAllLink='/payoutGateway' />
      <div><a href={appConfig.doc_main} target="blank"><ButtonGray>Documentation</ButtonGray></a></div>
      <ButtonGray onClick={() => { device.deleteToken(); window.location.reload(); }}>Logout</ButtonGray>
    </div>
  );
};

export default Menu;
