import { Button, Collapse, IconButton, Tooltip } from '@material-tailwind/react';
import React, { useState } from 'react';
import { Link, Send } from 'react-feather';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import Timestamp from 'react-timestamp';
import { useCallAPI } from '../../../Hooks/callAPI';
import useDynamicTable, { DynamicPagination } from '../../../Hooks/dynamicTable';
import { useHelpers } from '../../../Hooks/helpers';
import appConfig from '../../../Utility/AppConfig';
import { FormDate, FormInput, FormSelect } from '../../../Utility/Inputs';
import { ContentLoading } from '../../../Utility/Utility';

const PaymentGatewayTransactions = () => {

  const helper = useHelpers();
  const callAPI = useCallAPI();

  const [showSearch, setShowSearch] = useState(false);

  let defaults = {
    payment_id: '',
    order_id: '',
    productName: '',
    tokenId: '',
    settledTo: '',
    amount: '',
    status: ''
  }
  const [searchFilters, setSearchFilters] = useState(defaults);

  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  const handleSearchSubmit = () => {
    try {
      if(fromDate !== '') {
        if(toDate === '') { throw 'Till date is required' }
        if(Math.floor(Date.parse(fromDate) / 1000) > Math.floor(Date.parse(toDate) / 1000)) { throw 'Till date cannot be smaller than from date' }
      }
      setSearchFilters(getValues());
      handlePageChange(1); // Reset to first page when searching
    } catch (error) {
      toast.error(error);
    }
  };

  const handleReset = () => {
    reset();
    setSearchFilters(defaults);
    setFromDate('');
    setToDate('');
    handlePageChange(1);
  };

  const { data, loading, currentPage, totalPages, handlePageChange } = useDynamicTable('merchants/getpaymenttransactions', fromDate, toDate, searchFilters);

  let tableHead = [
    <>Payment ID<br/>Order ID (Product Name)</>, 
    <>Amount</>,
    <>Status</>,
    <>Token</>,
    <>Payer<br/>Hash</>,
    <>SettledTo</>,
    <>Date</>,
    <></>
  ];

  const {
      register, reset, formState: { errors, isValid, isDirty }, handleSubmit, getValues, setValue, control, watch
  } = useForm({ mode: 'onChange' })
  
  const sendWebhook = async (payId) => {
    try {
      const res = await callAPI('merchants/sendWebhook', { payId: payId, type: 'payments' });
      toast.success(JSON.stringify(res));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="h-full w-full">
      <div className='flex justify-between items-center align-top mb-4'>
        <h6 className="text-white text-lg font-bold">
          Transactions
        </h6>
        <Button size='sm' color='purple' variant='gradient' onClick={() => setShowSearch(!showSearch)}>{!showSearch ? 'Search' : 'Hide'}</Button>
      </div>
      <Collapse open={showSearch}>
        <div className='mb-5 border-4 border-[#7b7b7b]/10 px-2 py-5 rounded-10'>
          <form onSubmit={handleSubmit(handleSearchSubmit)}>
            <div className='flex flex-wrap justify-center gap-3'>
              <FormInput name="payment_id" placeholder="Payment ID" reg={register} formerror={false} errors={errors} isdirty={isDirty ? 1 : 0} />
              <FormInput name="order_id" placeholder="Order ID" reg={register} formerror={false} errors={errors} isdirty={isDirty ? 1 : 0} />
              <FormInput name="productName" placeholder="Product Name" reg={register} formerror={false} errors={errors} isdirty={isDirty ? 1 : 0} />
              <FormInput name="settledTo" placeholder="Settled To Address" reg={register} formerror={false} errors={errors} isdirty={isDirty ? 1 : 0} />
              <FormInput name="amount" placeholder="Amount" reg={register} formerror={false} errors={errors} isdirty={isDirty ? 1 : 0} />
              <FormSelect name='tokenId' reg={register} formerror={false} errors={errors} isdirty={isDirty ? 1 : 0} 
                options={helper.getAllTokenSelectOptions('Select Token')}
              />
              <FormSelect name='status' reg={register} formerror={false} errors={errors} isdirty={isDirty ? 1 : 0} 
                options={[
                  { value: '', label: 'Select a status' },
                  { value: 'created', label: 'Created' },
                  { value: 'received', label: 'Received' },
                  { value: 'completed', label: 'Completed' },
                  { value: 'failed', label: 'Failed' },
                  { value: 'expired', label: 'Expired' }
                ]}
              />
              <FormDate name="fromDate" setDate={setFromDate} date={fromDate} placeholder="From Date" errors={errors} />
              <FormDate name="toDate" setDate={setToDate} date={toDate} placeholder="Till Date" errors={errors} />
            </div>
            <div className='flex justify-center mt-4 gap-2'>
              <Button type='submit' color='green' variant='gradient' disabled={loading} loading={loading}>Search</Button>
              <Button type='submit' color='black' variant='gradient' onClick={handleReset}>Reset</Button>
            </div>
          </form>
        </div>
      </Collapse>
      <div className="w-full overflow-scroll px-0 table-custom">
        <table className="w-full">
          <thead>
            <tr>
              {tableHead.map((head, key) => (
                <th key={key} className="">{head}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {(data.length > 0 && !loading) && data.map(
              (d, index) => {
                const classes =  "p-4";
                return (
                  <tr key={d.payment_id}>
                    <td className={classes}>
                      <span className='font-bold text-xs'>{d.payment_id}</span><br/>{d.order_id} ({d.productName})
                    </td>
                    <td className={classes}>
                      <helper.AmountToCurrency amount={d.amount/100} />
                    </td>
                    <td className={classes}>
                      <helper.renderStatusChip status={d.status} />
                    </td>
                    <td className={classes}>
                      <helper.getTokenData id={d.tokenId} /><br/>
                    </td>
                    <td className={classes}>
                      <helper.trimAddress address={d.payer} copy/><br/>
                      <helper.trimAddress address={d.hash} copy/>
                    </td>
                    <td className={classes}>
                      <helper.trimAddress address={d.settledTo} copy/>
                    </td>
                    <td className={classes}>
                      <Timestamp date={d.created} /><br/>
                      <Timestamp date={d.created} relative className='opacity-60' />
                    </td>
                    <td className='flex gap-1'>
                      <Tooltip content='Open Pay Link'><a href={appConfig.payLink + d.payment_id} target='blank'><IconButton size='sm' color='amber' variant='gradient'><Link size={13} /></IconButton></a></Tooltip>
                      <Tooltip content='Send Webhook'><IconButton disabled={d.status !== 'completed'} onClick={() => sendWebhook(d.payment_id)} size='sm' color='indigo' variant='gradient'><Send size={13} /></IconButton></Tooltip>
                    </td>
                  </tr>
                );
              },
            )}
            {loading && 
              <tr>
                <td colSpan={tableHead.length}><div className='flex justify-center pt-3'><ContentLoading /></div></td>
              </tr>
            }
            {((!data || data.length === 0) && !loading) && 
              <tr>
                <td colSpan={tableHead.length}><div className='flex justify-center pt-3 opacity-50'>No rows found</div></td>
              </tr>
            }
          </tbody>
        </table>
      </div>
      <DynamicPagination totalPages={totalPages} currentPage={currentPage} handlePageChange={handlePageChange} />
    </div>
  );
};

export default PaymentGatewayTransactions;
