import { Button } from "@material-tailwind/react";

export const ButtonGray = ({ children, ...props }) => {
  return (
    <Button
      {...props}
      className="capitalize btn-gray bg-[#474747] text-white text-sm font-bold px-6 py-3 rounded-lg_next"
    >
      {children}
    </Button>
  );
};

export const ButtonPrimary = ({ children, ...props }) => {
  return (
    <Button
      {...props}
      className="capitalize btn-primary border border-primary bg-primary text-white text-base font-bold px-10 py-4 rounded-xl"
    >
      {children}
    </Button>
  );
};

export const ButtonPrimaryOutline = ({ children, ...props }) => {
  return (
    <Button
      {...props}
      className="capitalize btn-primary-outline border border-primary bg-transparent text-white text-base font-bold px-5 py-4 rounded-xl"
    >
      {children}
    </Button>
  );
};

export const ButtonPrimaryType2 = ({ children, ...props }) => {
  return (
    <Button
      {...props}
      className="capitalize btn-primary border border-primary bg-primary text-white text-base font-bold px-4 py-[14px] rounded-xl flex items-center justify-between min-w-[245px]"
    >
      {children}
    </Button>
  );
};

export const ButtonPrimaryGradient = ({ children, ...props }) => {
  return (
    <Button
      {...props}
      className="capitalize btn-primary bg-gradient-to-b from-[#52B86E] to-[#3E9055] text-white text-base font-bold px-10 py-4 rounded-xl"
    >
      {children}
    </Button>
  );
};
