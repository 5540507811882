import toast from 'react-hot-toast';
import { useAccount, useChainId, useSwitchChain } from 'wagmi';
import { useUserAuth } from "../Contexts/authContext";

export const useSwitchWalletData = () => {

    const auth = useUserAuth();
    const currChainId = useChainId();
    const switchChain = useSwitchChain();
    const account = useAccount();

    const chain = async (requiredChain) => {
        return await new Promise((resolve, reject) => {
            if(currChainId !== requiredChain*1) {
                const toastId = toast.loading('Confirm network switch from your wallet to proceed');
                switchChain.switchChain({chainId: requiredChain}, { onSuccess: () => {
                    toast.dismiss(toastId);
                    resolve(true);
                }, onError: (error) => {
                    toast.dismiss(toastId);
                    reject(error)
                }});
            } else {
                resolve(false);
            }
        });
    }

    const wallet = async (requiredAddr = false, errorMsg = false) => {
        return await new Promise((resolve, reject) => {
            console.log(account);
            const addrRequired = requiredAddr || auth?.appData.merchant.address;
            if(addrRequired.toLowerCase() !== account.address.toLowerCase()) {
                toast.error(errorMsg ? errorMsg : 'Switch to merchant registered wallet');
                reject('Change to : ' + auth?.appData.merchant.address);
            } else {
                resolve(true);
            }
        });
    }
    
    return { chain, wallet };
}