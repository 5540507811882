import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import ManageMerchant from "../../Components/dash/manage/Merchant";
import ManagePaymentGateway from "../../Components/dash/manage/PaymentGateway";
import { ButtonGray, ButtonPrimaryGradient } from "../../Utility/Buttons";
import { PrimaryHeading } from "../../Utility/Headings";
import { ContentBox, LoadingData } from "../../Utility/Utility";
import ManagePayoutSystem from "../../Components/dash/manage/PayoutSystem";
import ShowMerchantKeys from "../../Components/dash/manage/Keys";

const Manage = () => {

  const location = useLocation();
  const params = useParams();

  const [ShowView, setShowView] = useState(null);
  
  useEffect(() => {
    switch (params.view) {
      case 'merchant':
        setShowView(<ManageMerchant />);
        break;
  
      case 'paymentgateway':
        setShowView(<ManagePaymentGateway />);
        break;
  
      case 'payoutsystem':
        setShowView(<ManagePayoutSystem />);
        break;

      case 'keys':
        setShowView(<ShowMerchantKeys />);
        break;

      default:
        break;
    }

    return;
  }, [location.pathname])

  return (
    <div>
      <div className="mb-4 lg:mb-6">
        <PrimaryHeading>Manage</PrimaryHeading>
      </div>
      <div className="tabs flex flex-wrap gap-3 mb-2">
        <HandleMenu name='Merchant' link='/manage/merchant' />
        <HandleMenu name='Payment Gateway' link='/manage/paymentgateway' />
        <HandleMenu name='Payout System' link='/manage/payoutsystem' />
        <HandleMenu name='API Keys' link='/manage/keys' />
      </div>
      <ContentBox>
        <div className="space-y-6">
          {ShowView !== null ? <>{ShowView}</> : <LoadingData />}
        </div>
      </ContentBox>
    </div>
  );
};

const HandleMenu = ({ name, link }) => {
  const location = useLocation();
  return (
    <div>
    {location.pathname.includes(link) ?
      <Link to={link}><ButtonPrimaryGradient>{name}</ButtonPrimaryGradient></Link>
    :
      <Link to={link}><ButtonGray>{name}</ButtonGray></Link>
    }
    </div>
  );
}

export default Manage;
